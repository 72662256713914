<template>
  <div class="welcome_wrap">
    <div class="welcome_conent">
      <div class="tubiao">
        <img src="@/assets/homepage/bg-head.png"
             alt=""
             srcset="">
      </div>
      <div class="right_welcome">
        <div class="welcome_title">
          {{username?username + '，':''}} 您好！欢迎使用天天进步中考智能学伴教师端
        </div>
        <div class="intro">
          天天进步中考智能学伴是由广东善利智能科技有限公司自主研发，
          致力于通过人工智能让学生进行科学、高效、个性化备考的一款革命性的智能教育产品。
          目前具有整卷测试、考点训练、学情分析、错题本、优质课程等功能，助力学生发现学习重点，解决学习难点。
          教师端旨在帮助教师协助学生学习备考。</div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'welcome',
  data () {
    return {
      username: window.localStorage.getItem('userName'),
    }
  },
  mounted () {
    this.$nextTick(() => {
      document.getElementsByClassName('view_warp')[0].style.background = '#F2F2F2'
      document.getElementsByClassName('view_warp')[0].style.padding = '20px'
    })
  },
  methods: {
  }
}

</script>

<style lang="scss" scoped>
.welcome_wrap {
  // background:#F2F2F2 ;
  border: 1px solid #efefef;
  // margin: 20px;
  .welcome_conent {
    height: 722px;
    background: #fff;
    padding: 20px;
    display: flex;
    border-radius: 5px;
    .tubiao {
      width: 80px;
      height: 100px;
      img {
        margin-right: 20px;
        width: 60px;
        height: 60px;
      }
    }
    .right_welcome {
      padding-right: 28px;
      .welcome_title {
        font-size: 18px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
      }
      .intro {
        margin-top: 10px;
        font-size: 15px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #999999;
        line-height: 28px;
      }
    }
  }
}
</style>